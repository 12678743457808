<template>
    <div>Hello, Example Component!</div>
</template>

<script>
export default {
    mounted() {
        console.log('Example component mounted.')
    }
}
</script>