<template>
    <div class="mr-2">
            <button type="button" @click="modalIsOpen = !modalIsOpen" :class="classes">
                <i :class="icon"></i> {{ text }}
            </button>
        <DeleteModal
            v-if="modalIsOpen"
            :url="url"
            :title="title"
            :message="message"
            :button-text="buttonText"
            @close="modalIsOpen = false" />
    </div>
</template>

<script>
    import DeleteModal from './DeleteModal';

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            classes: {
                type: String,
                required: false,
                default: 'btn btn-sm btn-danger',
            },
            text: {
                type: String,
                required: false,
            },
            icon: {
                type: String,
                required: false,
                default: 'fa fa-trash-alt',
            },
            title: {
                type: String,
                required: false,
                default: null,
            },
            message: {
                type: String,
                required: false,
                default: null,
            },
            buttonText: {
                type: String,
                required: false,
            }
        },
        components: {
            DeleteModal
        },
        data() {
            return {
                modalIsOpen: false,
            }
        },
    }
</script>