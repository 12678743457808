<template>
    <BaseModal @close="close()">
        <template slot="modal-header">
            <h5 class="modal-title">{{ title || 'Confirmar acción' }}</h5>
        </template>
        <template slot="modal-body">
        <div v-html="message || '¿Está seguro de eliminar este registro?'">
           
        </div>
    </template>
        <template slot="modal-footer">
            <form @submit="lockAndDestroy" :action="url" method="post">
                <input type="hidden" name="_method" value="delete">
                <input type="hidden" name="_token" :value="csrfToken">
                <button type="submit" class="btn btn-sm btn-primary">{{ buttonText }}</button>
            </form>
        </template>
    </BaseModal>
</template>

<script>
    import BaseModal from './BaseModal';

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                required: false,
                default: 'Eliminar',
            },
            message: {
                type: String,
                required: false,
                default: '¿Está seguro de eliminar este registro?',
            },
            buttonText: {
                type: String,
                required: false,
                default: 'Si, eliminar',
            },
            csrfToken: {
                type: String,
                required: false,
                default: window.token,
            }
        },
        components: {
            BaseModal,
        },
        methods: {
            close() {
                this.$emit('close');
            },
            lockAndDestroy() {
                window.lockScreen();
                this.close();
            }
        },
    }
</script>
