<template>
    <div>
        <div class="card p-1" v-if="loaded">
            <bar-chart :chart-data="chartdata" :options="options"></bar-chart>

            <div class="row">
                <div class="col-sm-3 pt-1">
                    <label class="mb-0" for="nivel">NIVEL</label>
                    <select v-model="nivelSelected" class="col-sm-12 p-1 texto" name="nivel" id="nivel">
                        <option selected value="GENERAL">GENERAL</option>
                        <option value="INICIAL">INICIAL</option>
                        <option value="PRIMARIA">PRIMARIA</option>
                        <option value="SECUNDARIA">SECUNDARIA</option>
                    </select>
                </div>
                <div class="col-sm-2 p-1" v-if="inputVisible">
                    <label class="mb-0" for="grado">GRADO</label>
                    <select v-model="gradoSelected" class="col-sm-12 p-1" name="grado" id="grado">
                        <option value="1ro">1ro</option>
                        <option value="2do">2do</option>
                        <option value="3ro">3ro</option>
                        <option value="4to">4to</option>
                        <option value="5to">5to</option>
                        <option value="6to">6to</option>
                    </select>
                </div>

                <div class="col-sm-2 p-1" v-if="inputVisible">
                    <label class="mb-0" for="paralelo">PARALELO</label>
                    <select v-model="paraleloSelected" class="col-sm-12 p-1" name="paralelo" id="paralelo">
                        <option value="A">A</option>
                        <option value="B">B</option>
                        <option value="C">C</option>
                        <option value="D">D</option>
                        <option value="F">F</option>
                        <option value="G">G</option>
                        <option value="H">H</option>
                    </select>
                </div>
                <div class="col-sm-2 pr-1 text-center">
                    <label class="mb-0 text-white" for="inputext">boton para imprimir</label>
                    <input class="btn btn-sm btn-primary" id="inputext" type="button" value="Filtrar"
                        v-on:click="enviarFiltrado" />
                </div>
                <div class="col-sm-2  text-center pr-1 pl-1">
                    <label class="mb-0 text-white" for="inputext">boton para imprimir</label>
                    <input v-on:click="export2image" class="btn btn-sm btn-success" id="inputext" type="button"
                        value="Descargar" />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    select.texto {
        font-size: 10px;
        width: 100%;
        height: 28px;
    }
</style>
<script>
    import BarChart from "./BarChart.js";
import axios from "axios";

export default {
    name: "grafico-serviciosbasicos",
    components: {
        BarChart,
    },
    data: () => ({
        loaded: false,
        chartdata: null,
        options: {
            type: Object,
            default: null,
        },
        tipo: {
            type: String,
            default: null,
        },
        nivelSelected: "GENERAL",
        gradoSelected: "1ro",
        paraleloSelected: "A",
        inputVisible: false,
    }),
    watch: {
        nivelSelected: function (val) {
            if (val == "GENERAL") {
                this.inputVisible = false;
            } else {
                this.inputVisible = true;
            }
        },
        /*chartData () {
     this.$data._chart.update()
      console.log('dd',this.$data);
    }*/
    },
    async mounted() {
        this.loaded = false;
        this.tipo = "GENERAL";
        this.enviarFiltrado();
    },
    methods: {
        export2image: function () {
             const canvas = this.$el.querySelector('canvas').toDataURL("image/png");
            let link = document.createElement("a");
            link.download = "image";
            link.href = canvas;
            link.click();
        },

        enviarFiltrado: async function () {
            if (this.nivelSelected == "GENERAL") {
                this.tipo = this.nivelSelected;
            } else {
                this.tipo =
                    this.gradoSelected.toUpperCase() +
                    " " +
                    this.paraleloSelected +
                    " " +
                    this.nivelSelected;
            }
            this.options = {
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: ["SERVICIOS BÁSICOS", this.tipo],
                },
                barPercentage: 0.5,
                barThickness: 6,
                maxBarThickness: 6,
                minBarLength: 2,
                responsive: true,
                legend: {
                    position: "top"
                },
                scales: {
                    yAxes : [{
                        type: 'linear',
                        ticks: {
                        beginAtZero: true,
                        stepSize: 2
                        }
                    }],
                    xAxes: [{
                        
                  
                   
                    }]
                },
                plugins: {
                    datalabels: {
                        color: "black",
                        anchor : "end",
                        align : "end",

                        font: {
                            weight: "bold",
                            size: 12,
                        },
                    },
                },
            };
            axios
                .get(
                    "/graficos/servicios-basicos/" +
                        this.nivelSelected +
                        "/" +
                        this.gradoSelected +
                        "/" +
                        this.paraleloSelected
                )
                .then((response) => {
                    // Obtenemos los datos
                    this.loaded = false;
                    this.chartdata = response.data.resultado;
                    this.loaded = true;
                })
                .catch((e) => {
                    // Capturamos los errores
                    console.error(e);
                });
        },
    },
};
</script>