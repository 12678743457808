<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-dialog modal-dialog-scrollable">
                <div class="modal-content p-0">
                    <slot name="modal-content">
                        <!-- Modal header -->
                        <div class="justify-content-center modal-header p-2">
                            <slot name="modal-header"></slot>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            <slot name="modal-body"></slot>
                        </div>

                        <!-- Modal footer -->
                        <div class="modal-footer d-flex justify-content-end p-1">
                            <button type="button" class="btn btn-sm btn-danger" @click="close()">Cancelar</button>

                            <slot name="modal-footer"></slot>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        methods: {
            close() {
                this.$emit('close');
            },
        },
        mounted() {
            document.querySelector('body').classList.add('modal-open');
        },
        destroyed() {
            document.querySelector('body').classList.remove('modal-open');
        },
    }
</script>