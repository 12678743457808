import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.plugins.unregister(ChartDataLabels);

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options'],
 
  mounted () {
    this.addPlugin(ChartDataLabels);
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options () {
       this.renderChart(this.chartData, this.options);
    },
    /*chartData () {
      this.$data._chart.update()
    
    }*/
  }
};
